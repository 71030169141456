import { enableProdMode, isDevMode, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, HttpClient } from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideServiceWorker } from '@angular/service-worker';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { provideAuth, getAuth, initializeAuth, indexedDBLocalPersistence } from '@angular/fire/auth';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { Capacitor } from '@capacitor/core';
import { provideRouter, RouteReuseStrategy } from '@angular/router';

import { LoggerModule } from 'ngx-logger';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreModule } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';

import { environment } from './environments/environment';
import { InterceptorTokenService } from './app/core/interceptor-token.service';
import { GuidedTourService, GuidedTourModule } from 'ngx-guided-tour';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app';
import { provideLottieOptions } from 'ngx-lottie';
import { routes } from './app/app-routing';

import { wordsReducer } from './app/core/store/words/words.reducer';
import { verbsReducer } from './app/core/store/verbs/verbs.reducer';
import { userReducer } from './app/core/store/user/user.reducer';
import { topicsReducer } from './app/core/store/topics/topics.reducer';
import { phrasesReducer } from './app/core/store/phrases/phrases.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { WordsEffects } from './app/core/store/words/words.effects';
import { VerbsEffects } from './app/core/store/verbs/verbs.effects';
import { UserEffects } from './app/core/store/user/user.effects';
import { TopicsEffects } from './app/core/store/topics/topics.effects';
import { PhrasesEffects } from './app/core/store/phrases/phrases.effects';
import { AppComponent } from './app/app.component';

import { register } from 'swiper/element/bundle';
// Dataclouder libraries:

import { AUTH_CONFIG } from 'projects/dataclouder/app-auth/src/lib/services/auth-token-settings';
import { provideChatAIService } from 'projects/dataclouder/conversation-system/src/lib/interfaces/conversation-ai.class';
import { ConversationAIService } from './app/core/data-services/conversation-ai.service';
import { ToastAlertService } from './app/core/system/toast-alerts.service';
import { LessonsV2Service } from './app/core/data-services/lessonsv2.service';
import { provideLessonsService } from '@dataclouder/lessons';
import { provideToastAlert } from '@dataclouder/core-components';

register();

if (environment.production) {
  enableProdMode();
}
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

bootstrapApplication(AppComponent, {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    importProvidersFrom(
      BrowserModule,
      LoggerModule.forRoot({ level: environment.logLevel }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      // Others
      StoreModule.forRoot({
        words: wordsReducer,
        verbs: verbsReducer,
        user: userReducer,
        topics: topicsReducer,
        phrases: phrasesReducer,
      }),
      StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
      EffectsModule.forRoot([WordsEffects, VerbsEffects, UserEffects, TopicsEffects, PhrasesEffects]),
      GuidedTourModule,
    ),
    DialogService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorTokenService, multi: true },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    GuidedTourService,
    // Firebase
    provideChatAIService(ConversationAIService),
    provideToastAlert(ToastAlertService), // basically wraps this { provide: TOAST_ALERTS_TOKEN, useExisting: ToastAlertService },
    provideLessonsService(LessonsV2Service),

    provideFirebaseApp(() => initializeApp(environment.firebase)),

    provideAuth(() => {
      if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence,
        });
      } else {
        return getAuth();
      }
    }),

    provideStorage(() => getStorage()),
    provideHttpClient(withInterceptorsFromDi()),
    provideLottieOptions({
      player: () => import('lottie-web'), // check if i can move this later
    }),
    provideAnimations(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),

    {
      provide: AUTH_CONFIG,
      useValue: {
        clientIds: {
          androidClientId: environment.mobile.androidClientId,
          webClientId: environment.clientId,
          iosClientId: environment.mobile.iosClientId,
        },
        settings: {
          loginRedirectUri: null,
          signupRedirectUri: null,
          afterLoginRedirectUri: null,
          appleRedirectURI: environment.mobile.appleRedirectURI,
        },
      },
    },
  ],
}).catch((err) => console.error(err));
