import { Injectable, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Observable, distinctUntilChanged, fromEvent, map } from 'rxjs';
import { RouteNames } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class MobileService {
  public eventInstall;
  public isMobileAgent: boolean;
  public hasReponsiveView: boolean;
  public hasMobileView: boolean;

  private readonly mobileWidthThreshold = 768; // Adjust the threshold as needed
  private readonly responsibleWidthThreshold = 994;
  private screenWidth$: Observable<number>;
  public screenHeight$: Observable<number>;
  private isMobile$: Observable<boolean>;

  constructor(private router: Router) {
    this.hasReponsiveView = window.innerWidth <= this.responsibleWidthThreshold;

    this.checkMobileAgent();

    this.screenWidth$ = fromEvent(window, 'resize').pipe(
      map(() => window.innerWidth),
      distinctUntilChanged(),
    );

    this.screenHeight$ = fromEvent(window, 'resize').pipe(
      map(() => window.innerHeight),
      distinctUntilChanged(),
    );

    this.isMobile$ = this.screenWidth$.pipe(
      map((width: number) => width <= this.responsibleWidthThreshold), // Add type assertion to 'width'
      distinctUntilChanged(),
    );

    this.isMobile$.subscribe((isResposible) => {
      console.log('isMobile', isResposible);
      this.hasReponsiveView = isResposible;
    });
  }

  public getDesktopOrMobilePath() {
    if (this.isNativeMobileOrMobileRoute()) {
      return RouteNames.Mobile;
    } else {
      return RouteNames.App;
    }
  }

  public navigateTo(route: string) {
    //TODO: new strategy works on /app or /m routes but don't need to add more probably this is usefull anymore
    if (this.isNativeMobileOrMobileRoute()) {
      route = RouteNames.Mobile + '/' + route;
      this.router.navigate([route]);
    } else {
      this.router.navigate([route]);
    }
  }

  public isMobileRoute(currentRoute: string = ''): boolean {
    if (!currentRoute) {
      currentRoute = this.router.url;
    }
    const firstRouteName = currentRoute.split('/')[1];

    return firstRouteName == 'm' ? true : false;
  }

  // Add this function to the class
  public getPlatform(): string {
    if (Capacitor.isNativePlatform()) {
      const platform = Capacitor.getPlatform();
      // Example usage in component:
      if (platform === 'ios') {
        // iOS specific code
        return 'ios';
      } else if (platform === 'android') {
        // Android specific code
        return 'android';
      } else {
        return 'web';
      }
    }
    return 'web';
  }

  public isNativeMobileOrMobileRoute() {
    if (Capacitor.isNativePlatform() || this.isMobileRoute()) {
      return true;
    } else {
      return false;
    }
  }

  getScreenWidth(): Observable<number> {
    return this.screenWidth$;
  }

  isMobile(): Observable<boolean> {
    return this.isMobile$;
  }

  public installByUser() {
    console.log('Debería hacer la instalación por el usuario');
    if (this.eventInstall) {
      this.eventInstall.prompt();
      console.log('Debio mandar el promt');
    }
  }

  private checkMobileAgent(): void {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // true for mobile device
      this.isMobileAgent = true;
    } else {
      // false for not mobile device
      this.isMobileAgent = false;
    }
  }
}
