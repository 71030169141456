import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { UserService } from 'src/app/core/data-services/user.service';
import { StripeService } from 'src/app/core/stripe.service';
import { PaymentsService } from 'src/app/core/system/payments.service';

@Component({
  selector: 'app-component-sales-letter',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './sales-letter.component.html',
  styleUrl: './sales-letter.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalesLetterComponent {
  public constructor(private stripeService: StripeService) {}

  isLoadingStripe = false;

  public async payStripe(months: number) {
    try {
      this.isLoadingStripe = true;
      await this.stripeService.payStripe(months);
    } finally {
      this.isLoadingStripe = false;
    }
  }
}
