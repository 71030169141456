import { Injectable } from '@angular/core';
import { ConversationPromptSettings, LearningExample, TranscriptionsWhisper } from '../classes';
import { AIAPI, ChatRole, Endpoints, UtilsAPI, VerbTense } from '../enums';
import { HttpService } from '../system/http.service';
import { UserService } from './user.service';
import { ConversationAIAbstractService } from 'projects/dataclouder/conversation-system/src/lib/interfaces/conversation-ai.class';
import { ConversationUserSettings, IConversationCard } from 'projects/dataclouder/conversation-system/src/public-api';
import { AudioSpeedRating } from '../constants';
import { ToastService } from '../system/toast.service';

export type AudioGenerated = { blobUrl: string; transcription: any };
export type TTSRequest = { text: string; voice: string; generateTranscription: boolean; speedRate: number; speed?: string; ssml?: string };

@Injectable({
  providedIn: 'root',
})
export class ConversationAIService implements ConversationAIAbstractService {
  constructor(private httpService: HttpService, private userService: UserService, private toastService: ToastService) {}

  async translateConversation(currentLang: string, targetLang: string, idCard: string): Promise<ConversationUserSettings> {
    const response = await this.httpService.postDataToService(
      `${Endpoints.ConversationCard.TranslateConversation}`,
      { currentLang, targetLang, idCard },
      'python',
    );

    return response;
  }

  async getConversationUserChatSettings(): Promise<any> {
    return this.userService.getUserSnapshot().settings.conversation as any;
  }

  // async getConversationPromptSettings() {
  //   return {} as any;
  // }

  async saveConversationSettings(settings: any) {
    return await this.httpService.postDataToService('api/conversation-ai/settings', settings);
  }

  async saveConversationCard(conversation: IConversationCard): Promise<IConversationCard> {
    if (conversation.id || conversation._id) {
      return await this.httpService.putDataToService(`${Endpoints.ConversationCard.Conversation}/${conversation._id}`, conversation);
    } else {
      return await this.httpService.postDataToService(`${Endpoints.ConversationCard.Conversation}`, conversation);
    }
  }

  async deleteConversationCard(id: string): Promise<any> {
    return await this.httpService.deleteDataFromService(`${Endpoints.ConversationCard.Conversation}/${id}`);
  }

  async findConversationCard(id: string): Promise<any> {
    return await this.httpService.getDataFromService(`${Endpoints.ConversationCard.Conversation}/${id}`);
  }
  async getAllConversationCards(): Promise<any[]> {
    return await this.httpService.getDataFromService(`${Endpoints.ConversationCard.Conversation}`);
  }

  // TODO: necesito ponerle un tipo al return.

  public async callChatCompletion(conversation: ConversationPromptSettings): Promise<any> {
    let messages = conversation.messages.map((m) => ({ content: m.content, role: m.role }));
    messages = messages.filter((m) => m.role != ChatRole.AssistantHelper);
    const conversationFiltered = { ...conversation, messages };

    return await this.httpService.postDataToService(`${AIAPI.GenerateChat}`, conversationFiltered, 'python');
  }

  public async getTextAudioFile(tts: TTSRequest): Promise<AudioGenerated> {
    const httpReq: any = await this.httpService.receiveFile(`${AIAPI.GetTTS}`, tts, 'python');
    const audioData = { blobUrl: null, transcription: null };

    const transcription = httpReq?.headers.get('transcription');

    if (transcription) {
      const data = JSON.parse(transcription);
      audioData.transcription = data;
    }

    const mp3 = window.URL.createObjectURL(httpReq.body);
    audioData.blobUrl = mp3;

    return audioData;
  }

  async saveConversationUserChatSettings(newSettings: any): Promise<any> {
    // TODO: deberia tener un metodo updateUserSettings() pero mientras tengo que pasar todo el objeto settings en vez de solo conversation
    const settings = { ...this.userService.getUserSnapshot().settings };
    const conversation: any = newSettings;

    conversation.speed = AudioSpeedRating[conversation.speed ?? 3];

    settings.conversation = conversation;

    await this.userService.updateUser({ id: this.userService.getUserSnapshot().id, settings });

    this.toastService.success('Se actualizaron las configuraciones', 'Listo');

    // this.conversationSettingsChanged.emit();

    // TODO: save chatOptions
    // return await this.httpService.postDataToUrl('http://localhost:8080', 'api/conversation-ai/user-chat-settings', settings);
  }
}
