import { Injectable } from '@angular/core';
import { ConfirmComponent } from './confirm.component';
import { DialogService } from 'primeng/dynamicdialog';
import { PromoOfferConfirmComponent } from './promo-offer-confirm/promo-offer-confirm.component';
import { SalesLetterService } from 'src/app/core/system/sales-letter.service';
import { WelcomeFollowerConfirmComponent } from './welcome-follower-confirm/welcome-follower-confirm.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  constructor(public pdialogService: DialogService, private salesLetter: SalesLetterService) {}

  openConfirm(title: string, message: string, acceptText: string) {
    const dialogRef = this.pdialogService.open(ConfirmComponent, {
      data: {
        message: message,
        title: title,
        acceptText,
      },
    });

    return dialogRef.onClose;
  }

  // TODO: esta es una mejor manera de confirmar. pero no esta implementada.
  confirm(options: { title: string; message: string; acceptText: string; cancelText: string; onAccept: () => void; onCancel: () => void }) {
    const dialogRef = this.pdialogService.open(ConfirmComponent, {
      data: {
        message: options.message,
        title: options.title,
        acceptText: options.acceptText,
      },
    });

    return dialogRef.onClose;
  }

  openOfferConfirm() {
    const dialogRef = this.pdialogService.open(PromoOfferConfirmComponent, {});

    dialogRef.onClose.subscribe((result) => {
      console.log('result', result);
      if (result == 'sales-letter') {
        this.salesLetter.openSalesLetterDialog();
      }
    });

    return dialogRef.onClose;
  }

  openFollowerConfirm() {
    const dialogRef = this.pdialogService.open(WelcomeFollowerConfirmComponent, {});

    dialogRef.onClose.subscribe((result) => {
      console.log('result', result);
      if (result == 'sales-letter') {
        this.salesLetter.openSalesLetterDialog();
      }
    });

    return dialogRef.onClose;
  }
}
