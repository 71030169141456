import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Auth,
  authState,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  IdTokenResult,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendEmailVerification,
  signInWithCredential,
  OAuthProvider,
} from '@angular/fire/auth';
import { firstValueFrom, from, of } from 'rxjs';
import { take, concatMap } from 'rxjs/operators';
import { GoogleAuth, User } from '@codetrix-studio/capacitor-google-auth';
import { Capacitor } from '@capacitor/core';
import { AUTH_CONFIG, AuthConfig } from './auth-token-settings';
import { SignInWithApple, SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthService {
  constructor(private router: Router, private auth: Auth, @Inject(AUTH_CONFIG) private authConfig: AuthConfig) {}

  public authState$ = authState(this.auth);

  async signInWithApple(): Promise<void> {
    try {
      const provider = new OAuthProvider('apple.com');
      provider.addScope('email'); // Request email scope if needed
      provider.addScope('name'); // Request name scope if needed
      const result = await signInWithPopup(this.auth, provider);
      console.log('User signed in with Apple:', result);
      this.router.navigate(['/']);
    } catch (error) {
      console.error('Error signing in with Apple:', error);
      throw error;
    }
  }

  async signInWithAppleToken(token: string): Promise<void> {
    try {
      const provider = new OAuthProvider('apple.com');
      const result = await signInWithCredential(this.auth, provider.credential({ idToken: token }));
      console.log('result', result);
      this.router.navigate(['/']);
    } catch (error) {
      console.error('Error signing in with Apple:', error);
      throw error;
    }
  }

  public tokenId$ = this.authState$.pipe(
    take(1),
    concatMap((auth: any) => {
      if (auth === null) {
        return of(null);
      }
      return from(auth.getIdToken());
    }),
  );

  refreshToken(): void {
    this.auth.currentUser?.getIdToken(true);
  }

  async signInGoogleWithToken(token: string): Promise<any> {
    return signInWithCredential(this.auth, GoogleAuthProvider.credential(token));
  }

  async signInWithCredential(credential: any): Promise<any> {
    return signInWithCredential(this.auth, credential);
  }

  async logOut(redirectRoute = '/signup'): Promise<void> {
    await this.auth.signOut();
    this.router.navigate([redirectRoute]);
  }

  async signWithProvider(provider: 'google' | 'facebook'): Promise<void> {
    try {
      const authProvider = provider === 'google' ? new GoogleAuthProvider() : new FacebookAuthProvider();
      await signInWithPopup(this.auth, authProvider);
      this.router.navigate(['/']);
    } catch (error) {
      console.error('Error signing in with provider:', error);
      throw error;
    }
  }

  async register(email: string, password: string): Promise<void> {
    try {
      await createUserWithEmailAndPassword(this.auth, email, password);
      this.router.navigate(['/']);
    } catch (error) {
      console.error('Error registering:', error);
      throw error;
    }
  }

  async sendEmailVerification(): Promise<void> {
    if (this.auth.currentUser) {
      await sendEmailVerification(this.auth.currentUser);
    }
  }

  async signWithEmailPassword(email: string, password: string): Promise<void> {
    try {
      await signInWithEmailAndPassword(this.auth, email, password);
      this.router.navigate(['/']);
    } catch (error) {
      console.error('Error signing in:', error);
      throw error;
    }
  }

  async getTokenResult(): Promise<IdTokenResult | null> {
    return this.auth.currentUser?.getIdTokenResult();
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      const user = await firstValueFrom(authState(this.auth));
      return !!user; // converts to boolean, true if user exists, false if null
    } catch (error) {
      console.error('Auth check failed:', error);
      return false;
    }
  }

  async sendPasswordResetEmail(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email);
  }

  async loginWithGoogle() {
    try {
      if (Capacitor.getPlatform() === 'web') {
        await this.signWithProvider('google');
      } else {
        console.log('platform ', Capacitor.getPlatform());
        const clientId = Capacitor.getPlatform() === 'ios' ? this.authConfig.clientIds.iosClientId : this.authConfig.clientIds.androidClientId;
        console.log('Probando con ', clientId);

        await GoogleAuth.initialize({
          clientId: clientId,
          scopes: ['profile', 'email'],
          grantOfflineAccess: true,
        });

        const user: User = await GoogleAuth.signIn();
        const data = await this.signInGoogleWithToken(user.authentication.idToken);
        this.router.navigate(['/']);
      }
    } catch (error) {
      console.error('Error signing in with Google:', error);
      throw error;
    }
  }

  async selectAppleLogin() {
    if (Capacitor.getPlatform() === 'web') {
      await this.loginWithApple();
    } else {
      this.loginWithAppleNative();
    }
  }

  async loginWithApple() {
    try {
      await this.signInWithApple();
    } catch (error: any) {
      console.error('Apple login error:', error);
    }
  }

  async loginWithAppleNative() {
    let options: SignInWithAppleOptions = {
      clientId: this.authConfig.clientIds.iosClientId,
      redirectURI: this.authConfig.settings.appleRedirectURI,
      scopes: 'email',
      state: '12345',
    };

    try {
      const result = await SignInWithApple.authorize(options);
      await this.signInWithAppleToken(result.response.identityToken);
    } catch (error) {
      console.error('Apple login error:', error);
    }
  }
}
