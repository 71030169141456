import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { MobileService } from 'src/app/core/system/mobile.service';

@Component({
  selector: 'app-promo-offer-confirm',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './promo-offer-confirm.component.html',
  styleUrl: './promo-offer-confirm.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoOfferConfirmComponent {
  constructor(public ref: DynamicDialogRef, private mobileService: MobileService) {}

  public platform = this.mobileService.getPlatform();
  public isAndroid: boolean;

  public accept() {}

  public toSocialNetwork(social: string) {
    let url;
    if (social === 'instagram') {
      url = 'https://www.instagram.com/polilan_app';
    } else if (social === 'tiktok') {
      url = 'https://www.tiktok.com/@polilan_app';
    } else if (social === 'youtube') {
      url = 'https://www.youtube.com/@polilan_app';
    }

    window.open(url, '_blank');
  }

  public openSalesLetter() {
    this.ref.close('sales-letter');
  }
}
