import { InjectionToken } from '@angular/core';

export interface AuthConfig {
  clientIds: {
    androidClientId: string;
    iosClientId: string;
    webClientId: string;
  };
  settings: {
    loginRedirectUri: string;
    signupRedirectUri: string;
    afterLoginRedirectUri: string;
    appleRedirectURI: string;
  };
}

export const AUTH_CONFIG = new InjectionToken<AuthConfig>('auth.config');
